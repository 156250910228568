import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { useQuery } from "src/query";

interface IEmailContent {
  html: string;
  txt: string;
  subjectLine: string;
}

export const useEmailContentQuery = (
  ident: string,
  options?: Omit<
    UseQueryOptions<any, any, IEmailContent, QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  return useQuery<IEmailContent>({
    ...options,
    queryKey: ["emailContent", ident],
    queryFn: async () => {
      const response = await axios.get(`/v1/emails/ident/${ident}/`);
      return response.data;
    },
  });
};
