import { FormLinks, ToastContext } from "@curaleaf-international/components";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useContext } from "react";
import { useLocation } from "wouter";

import {
  useDeleteMassEmailMutation,
  useMassEmailQuery,
} from "src/queries/massEmails";

interface IProps {
  unsentMassEmailId: string;
}

const DeleteMassEmailConfirm = ({ unsentMassEmailId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { mutateAsync: deleteMassEmail } = useDeleteMassEmailMutation();
  const { addToast } = useContext(ToastContext);
  const { data } = useMassEmailQuery(unsentMassEmailId);

  const onDeleteSubmit = async () => {
    try {
      await deleteMassEmail(unsentMassEmailId);
      setLocation("/tools/#mass-emails");
    } catch {
      addToast("Not able to delete", "error");
      setLocation("/tools/#mass-emails");
    }
  };

  return (
    <Card>
      <CardContent>
        <Alert severity="warning">
          You are about to delete the email with subject line: "
          {data?.subjectLine}"
        </Alert>
      </CardContent>
      <CardActions>
        <Button variant="contained" onClick={onDeleteSubmit}>
          Confirm delete
        </Button>
        <FormLinks
          links={[
            {
              label: "Back",
              to: "/tools/#mass-emails",
            },
          ]}
        />
      </CardActions>
    </Card>
  );
};

export default DeleteMassEmailConfirm;
