import {
  FormLinks,
  Title,
  ToastContext,
  Value,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { useLocation, Link as WLink } from "wouter";

import { useClinicianQuery, useUnarchiveClinicianMutation } from "src/queries";

interface IProps {
  clinicianId: string;
}

const UnarchiveClinician = ({ clinicianId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: clinician } = useClinicianQuery(clinicianId);
  const { mutateAsync: unarchiveClinician } =
    useUnarchiveClinicianMutation(clinicianId);

  const onClick = async () => {
    try {
      await unarchiveClinician();
      addToast("Clinician unarchived", "success");
      setLocation("/clinicians/#archived");
    } catch {
      addToast("Try again", "error");
    }
  };

  if (clinician === undefined) {
    return <Skeleton />;
  }

  return (
    <>
      <Title
        title="Unarchive Clinician"
        breadcrumbs={[
          { label: `${clinician.name}`, to: `/clinicians/${clinicianId}/` },
          { label: "Unarchive Clinician" },
        ]}
      />
      <Divider />
      <Card>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Display Name</TableCell>
                <TableCell>
                  <Value text={clinician?.name} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Archived</TableCell>
                <TableCell>
                  {clinician?.archived ? (
                    <Value dateTime={clinician?.archived} />
                  ) : (
                    "No"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Archived By</TableCell>
                <TableCell>
                  <Link
                    component={WLink}
                    href={`/staff/${clinician?.archivedBy}/`}
                  >
                    <Value text={clinician?.archivedByEmail} />
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>
                  <Value text={clinician?.clinicianType} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Specialisms</TableCell>
                <TableCell>
                  <Value
                    text={clinician?.specialisms
                      .map((specialism) => specialism)
                      .join(", ")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <CardActions>
          <Button
            disabled={clinician.archived === null}
            variant="contained"
            onClick={onClick}
          >
            Unarchive clinician
          </Button>
          <FormLinks
            links={[
              {
                label: "Back",
                to: `/clinicians/${clinicianId}/`,
              },
            ]}
          />
        </CardActions>
      </Card>
    </>
  );
};

export default UnarchiveClinician;
