import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { MassEmail, newMassEmail } from "src/models";
import { useMutation, useQuery } from "src/query";

export interface IMassEmailData {
  subjectLine: string;
  body: string;
}

export const useCreateMassEmailMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IMassEmailData) =>
      await axios.post("/v1/mass-emails/", {
        subjectLine: data.subjectLine,
        body: data.body,
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["massEmails"] }),
  });
};

export const useUpdateMassEmailMutation = (massEmailId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IMassEmailData) =>
      await axios.put(`/v1/mass-emails/${massEmailId}/`, data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["massEmails"] }),
  });
};

export const useDeleteMassEmailMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (massEmailId: string) =>
      await axios.delete(`/v1/mass-emails/${massEmailId}/`),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["massEmails"] }),
  });
};

export const useMassEmailsQuery = () => {
  return useQuery<MassEmail[]>({
    queryKey: ["massEmails"],
    queryFn: async () => {
      const response = await axios.get("/v1/mass-emails/");
      return response.data.massEmails.map((json: unknown) =>
        newMassEmail(json),
      );
    },
  });
};

export const useMassEmailQuery = (massEmailId: string) => {
  return useQuery<MassEmail>({
    queryKey: ["massEmails", massEmailId],
    queryFn: async () => {
      const response = await axios.get(`/v1/mass-emails/${massEmailId}/`);
      return newMassEmail(response.data);
    },
  });
};
