import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import { useState } from "react";

import { useEmailContentQuery } from "src/queries";

type TabState = "html" | "txt";
interface IProps {
  subjectLine: string;
  body: string;
  patientName?: string;
}

const MassEmailPreview = ({ subjectLine, body, patientName }: IProps) => {
  const [tab, setTab] = useState<TabState>("html");
  const { data } = useEmailContentQuery("mass_email");

  const replacePlaceholders = (text: string) => {
    const replacements: Record<string, string> = {
      "{{ contact_name }}": patientName ? patientName : "Patient",
      "{{ body_text }}": body.replace(/\n/g, "<br />"),
    };

    let replacedText = text;
    Object.entries(replacements).forEach(([placeholder, content]) => {
      replacedText = replacedText.replace(placeholder, content);
    });
    return replacedText;
  };

  return (
    <>
      <DialogTitle>{subjectLine}</DialogTitle>
      <DialogContent>
        <TabContext value={tab}>
          <TabList
            aria-label="Email menu"
            onChange={(_, value: string) => setTab(value as TabState)}
            sx={{ marginBottom: 2 }}
          >
            <Tab label="HTML" value="html" />
            <Tab label="Plain txt" value="txt" />
          </TabList>

          <TabPanel value="html">
            <Box
              dangerouslySetInnerHTML={{
                __html: data?.html
                  ? (replacePlaceholders(data.html)
                      .replace(
                        "cid:Logo.png",
                        "/static/media/CuraleafClinic_Colour.png",
                      )
                      .replace(
                        "cid:CQCLogo.png",
                        "/static/media/CQCLogo.png",
                      ) ?? "")
                  : "",
              }}
            />
          </TabPanel>
          <TabPanel value="txt">
            <Box
              dangerouslySetInnerHTML={{
                __html: data?.txt
                  ? replacePlaceholders(data.txt).replace(/\n/g, "<br />")
                  : "",
              }}
            />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </>
  );
};

export default MassEmailPreview;
