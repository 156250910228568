import { Title, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link as WLink } from "wouter";

import FormulasTable from "src/pages/Formulas/FormulasTable";

type States =
  | "prescribable"
  | "prescribableIfRewrite"
  | "archived"
  | "pending"
  | "unprescribable"
  | "nonCannabis";

const Formulas = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tab, setTab] = useHash<States>("prescribable");
  const open = Boolean(anchorEl);
  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={WLink} to={"/formulas/new/"}>
          New Non-Cannabis Formula
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title="Formulas"
      />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as States)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Prescribable" value="prescribable" />
            <Tab
              label="Prescribable If Rewrite"
              value="prescribableIfRewrite"
            />
            <Tab label="Unprescribable" value="unprescribable" />
            <Tab label="Pending" value="pending" />
            <Tab label="Archived" value="archived" />
            <Tab label="Non-Cannabis" value="nonCannabis" />
          </TabList>
        </TabContext>
        <Divider />
        <FormulasTable state={tab} />
      </Card>
    </>
  );
};

export default Formulas;
