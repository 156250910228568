import { z } from "zod";

import { ClinicalReviewState } from "src/models/clinicalReview";

const clinicalReviewStateHistorySchema = z.object({
  id: z.number().int().positive(),
  clinicalReviewId: z.string().uuid(),
  reason: z.string().nullable(),
  state: z.nativeEnum(ClinicalReviewState),
  staffId: z.number().int().positive(),
  staffEmail: z.string().email(),
  timestamp: z.coerce.date(),
});

export type ClincalReviewStateHistory = z.infer<
  typeof clinicalReviewStateHistorySchema
>;

export const newClinicalReviewStateHistory = (data: unknown) =>
  clinicalReviewStateHistorySchema.parse(data);
