import {
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { SubscriptionType } from "src/models";
import { useCreateSubscriptionMutation, usePatientQuery } from "src/queries";

const FormSchema = z.object({
  reason: z.string(),
  stripeId: z.string().regex(/^sub_.*|$/),
  type: z.nativeEnum(SubscriptionType),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  patientId: string;
}

const CreateSubscription = ({ patientId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createSubscription } = useCreateSubscriptionMutation();
  const { data: patient } = usePatientQuery(patientId);
  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      reason: "",
      stripeId: "",
      type: SubscriptionType.CURA_CL,
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      const subscriptionId = await createSubscription({
        patientId,
        reason: data.reason,
        stripeId: data.stripeId.trim() === "" ? null : data.stripeId.trim(),
        type: data.type,
      });
      addToast("Subscription created", "success");
      setLocation(`/subscriptions/${subscriptionId}/`);
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "ACTIVE_OR_PENDING_SUBSCRIPTION_EXISTS"
      ) {
        addToast(
          "Patient already has an active or pending subscription",
          "error",
        );
      } else {
        addToast(`Try Again ${error}`, "error");
      }
    }
  };

  const values = methods.watch();

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: `/patients/${patientId}/#subscriptions`,
            label: "All Patient Subscriptions",
          },
          { label: "Start Subscription" },
        ]}
        title={`New Subscription - ${patient?.name ?? ""}`}
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <SelectField
                label="Subscription Type"
                name="type"
                fullWidth
                options={Object.keys(SubscriptionType).map((subscription) => ({
                  value: subscription.toString(),
                }))}
                required
              />
              {[SubscriptionType.CURA_CL, SubscriptionType.CURA_CLI].includes(
                values.type,
              ) ? (
                <TextField
                  fullWidth
                  label="Stripe subscription ID"
                  name="stripeId"
                  placeholder="sub_"
                  required
                />
              ) : null}
              <TextField fullWidth label="Reason" name="reason" required />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Create" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/patients/${patientId}/#subscriptions`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CreateSubscription;
