import {
  ToastContext,
  Title,
  SubmitButton,
  FormLinks,
  TextField,
  SelectField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { ClinicalReviewType } from "src/models";
import { usePatientQuery, useCreateClinicalReviewMutation } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  patientId: string;
}

const FormSchema = z.object({
  reason: z.string(),
  type: z.union([z.nativeEnum(ClinicalReviewType), z.literal("")]),
});
type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

const CreateRequestForClinicalReview = ({ patientId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createRequestForClinicalReview } =
    useCreateClinicalReviewMutation();
  const { data: patient } = usePatientQuery(patientId);

  const methods = useForm<FormType>({
    defaultValues: {
      reason: "",
      type: "",
    },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await createRequestForClinicalReview({
        reason: data.reason,
        patientId: patientId,
        type: data.type as ClinicalReviewType,
      });
      setLocation(`/patients/${patientId}/`);
      addToast("Clinical Review Request Created", "success");
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "REVIEW_EXISTS"
      ) {
        addToast("A pending review already exists for this type", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };
  return (
    <>
      <Title
        title={`${patient?.name ?? ""} - Request Clinical Review`}
        breadcrumbs={[
          { to: "/patients/", label: "Patients" },
          { to: `/patients/${patientId}/`, label: patient?.name ?? "" },
          { label: "Request Clinical Review" },
        ]}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid size={12}>
                  <SelectField
                    fullWidth
                    required
                    label="Review Type"
                    name="type"
                    options={Object.values(ClinicalReviewType).map((type) => ({
                      label: convertEnumValueToReadableString(type, " "),
                      value: type,
                    }))}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField fullWidth required label="Reason" name="reason" />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Create" />
              <FormLinks
                links={[{ label: "Back", to: `/patients/${patientId}/` }]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default CreateRequestForClinicalReview;
