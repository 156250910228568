import { Title, ToastContext } from "@curaleaf-international/components";
import Skeleton from "@mui/material/Skeleton";
import { useContext } from "react";
import { useLocation } from "wouter";

import MassEmailForm from "src/components/MassEmailForm";
import {
  IMassEmailData,
  useMassEmailQuery,
  useUpdateMassEmailMutation,
} from "src/queries/massEmails";

interface IProps {
  unsentEmailId: string;
}

const EditMassEmail = ({ unsentEmailId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: unsentEmail } = useMassEmailQuery(unsentEmailId);
  const { mutateAsync: updateMassEmail } =
    useUpdateMassEmailMutation(unsentEmailId);

  const onSubmit = async (data: IMassEmailData) => {
    try {
      await updateMassEmail(data);
      addToast("Mass email updated successfully", "success");
      setLocation("/tools/#mass-emails");
    } catch {
      addToast("Try Again", "error");
    }
  };

  return unsentEmail === undefined ? (
    <Skeleton height="80px" />
  ) : (
    <>
      <Title title="Edit Mass Email" />
      <MassEmailForm
        back="/tools/#mass-emails"
        label="Confirm updates"
        onSubmit={onSubmit}
        unsentMassEmailId={unsentEmail.id}
        initialValues={{
          subjectLine: unsentEmail.subjectLine,
          body: unsentEmail.body,
        }}
      />
    </>
  );
};

export default EditMassEmail;
