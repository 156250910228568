import {
  CircularLoader,
  Title,
  useHash,
} from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

import ClincalReviewStateHistory from "src/pages/ClinicalReview/ClincalReviewStateHistory";
import ClinicalReviewDetails from "src/pages/ClinicalReview/ClinicalReviewDetails";
import { useClinicalReviewQuery } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

type TabState = "details" | "history";

interface IProps {
  clinicalReviewId: string;
}

const ClinicalReview = ({ clinicalReviewId }: IProps) => {
  const { data: review } = useClinicalReviewQuery(clinicalReviewId);
  const [tab, setTab] = useHash<TabState>("details");

  return review === undefined ? (
    <CircularLoader />
  ) : (
    <>
      <Title
        title={
          review.patientName +
          " - " +
          convertEnumValueToReadableString(review.type, " ")
        }
        breadcrumbs={[
          { to: `/patients/${review.patientId}/`, label: review.patientName },
          {
            label: `Clinical Review - ${convertEnumValueToReadableString(review.type, " ")}`,
          },
        ]}
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList
            aria-label="Clinical Review menu"
            onChange={(_, value: string) => setTab(value as TabState)}
          >
            <Tab label="Details" value="details" />
            <Tab label="History" value="history" />
          </TabList>
        </Box>
        <TabPanel value="details">
          <ClinicalReviewDetails clinicalReviewId={clinicalReviewId} />
        </TabPanel>
        <TabPanel value="history">
          <ClincalReviewStateHistory clinicalReviewId={clinicalReviewId} />
        </TabPanel>
      </TabContext>
    </>
  );
};
export default ClinicalReview;
