import { useQuery } from "@curaleaf-international/components";
import axios from "axios";

import {
  newClinicalReviewStateHistory,
  ClincalReviewStateHistory,
} from "src/models";

export const useClinicalReviewStateHistoryQuery = (
  clinicalReviewId: string,
) => {
  return useQuery<ClincalReviewStateHistory[]>({
    queryKey: ["clinicalReviewStateHistory", clinicalReviewId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/clinical-review-state-history/clinical-review/${clinicalReviewId}/`,
        { signal },
      );
      return response.data.history.map((data: unknown) =>
        newClinicalReviewStateHistory(data),
      );
    },
  });
};
