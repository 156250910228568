import { SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { ClinicalReviewState, ClinicalReview } from "src/models";
import ClinicalReviewTableRow from "src/pages/ClinicalReviews/ClinicalReviewTableRow";
import { PaginationSettings } from "src/pagination";
import { useClinicalReviewsQuery } from "src/queries";

interface IProps {
  state: keyof typeof ClinicalReviewState;
  paginationSettings: PaginationSettings<ClinicalReview>;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  toggleSort: (columnName: keyof ClinicalReview) => void;
}

const ClinicalReviewsTable = ({
  state,
  paginationSettings,
  setPageNumber,
  setPageSize,
  toggleSort,
}: IProps) => {
  const { data } = useClinicalReviewsQuery({
    ...paginationSettings,
    stateFilter: [ClinicalReviewState[state]],
  });

  const { rows: reviews, totalRecordCount } = data || {};
  let rows = null;
  if (!reviews) {
    rows = <SkeletonRow cols={5} />;
  } else {
    rows = (
      <TableRow>
        <TableCell align="center" colSpan={5}>
          No Clinical Reviews Found
        </TableCell>
      </TableRow>
    );
    rows = reviews.map((review) => (
      <ClinicalReviewTableRow key={review.id} clinicalReview={review} />
    ));
  }

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <TableSortLabel<ClinicalReview>
                columnName={"type"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                Review Type
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">Patient</TableCell>
            <TableCell align="center">Assigned Consultant</TableCell>
            <TableCell align="center">State</TableCell>
            <TableCell align="center">
              <TableSortLabel<ClinicalReview>
                columnName={"stateTimestamp"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                Timestamp
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">Reason</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      <TablePagination<ClinicalReview>
        recordCount={totalRecordCount}
        paginationSettings={paginationSettings}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        rowsPerPageOptions={[100, 500, 750, 1000]}
      />
    </TableContainer>
  );
};

export default ClinicalReviewsTable;
