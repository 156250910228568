import {
  formatCurrency,
  formatDateTime,
  SkeletonRow,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { useAppointmentHistoryQuery } from "src/queries/appointments";

interface IProps {
  appointmentId: string;
}

const AppointmentHistory = ({ appointmentId }: IProps) => {
  const { data: appointmentHistory } =
    useAppointmentHistoryQuery(appointmentId);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Clinician</TableCell>
              <TableCell>Appointment Time</TableCell>
              <TableCell>Length</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Actioned By</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointmentHistory?.map((history) => (
              <TableRow key={history.id}>
                <TableCell>{history.status}</TableCell>
                <TableCell>{formatDateTime(history.timestamp)}</TableCell>
                <TableCell>
                  <Link
                    component={WLink}
                    to={`/clinicians/${history.clinicianId}/`}
                  >
                    {history.clinicianName}
                  </Link>
                </TableCell>
                <TableCell>{formatDateTime(history.startAt)}</TableCell>
                <TableCell>{history.length} minutes</TableCell>
                <TableCell>{history.appointmentType}</TableCell>
                <TableCell>{formatCurrency(history.price)}</TableCell>
                <TableCell>
                  {history.staffId ? (
                    <Link component={WLink} to={`/staff/${history.staffId}/`}>
                      {history.staffEmail}
                    </Link>
                  ) : history.patientId ? (
                    "Patient"
                  ) : null}
                </TableCell>
                <TableCell>{history.reason}</TableCell>
              </TableRow>
            )) ?? <SkeletonRow cols={9} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
export default AppointmentHistory;
