import { useQuery, useMutation } from "@curaleaf-international/components";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import {
  newClinicalReview,
  ClinicalReview,
  ClinicalReviewType,
  ClinicalReviewState,
} from "src/models";
import { PaginatedResult, PaginationSettings } from "src/pagination";

export const usePatientClinicalReviewsQuery = (patientId: string) => {
  return useQuery<ClinicalReview[]>({
    queryKey: ["clinicalReviews", patientId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/clinical-reviews/patient/${patientId}/`,
        { signal },
      );
      return response.data.clinicalReviews.map((data: unknown) =>
        newClinicalReview(data),
      );
    },
  });
};

export const useClinicalReviewsQuery = (
  pagination?: Partial<
    PaginationSettings<ClinicalReview> & { stateFilter: ClinicalReviewState[] }
  >,
) => {
  const { ...params } = pagination ?? {};
  return useQuery<PaginatedResult<ClinicalReview>>({
    queryKey: ["clinicalReviews", pagination],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/clinical-reviews/`, {
        params,
        paramsSerializer: { indexes: null },
        signal,
      });

      return {
        rows: response.data.clinicalReviews.map((data: unknown) =>
          newClinicalReview(data),
        ),
        totalRecordCount: response.data.totalRecordCount,
        paginationSettings: response.data.paginationSettings,
      };
    },
  });
};

export const useClinicalReviewQuery = (clinicalReviewId: string) => {
  return useQuery<ClinicalReview>({
    queryKey: ["clinicalReviews", clinicalReviewId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/clinical-reviews/${clinicalReviewId}/`,
        { signal },
      );
      return newClinicalReview(response.data);
    },
  });
};

interface IClinicalReviewData {
  patientId: string;
  reason: string;
  type: ClinicalReviewType;
}

export const useCreateClinicalReviewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IClinicalReviewData) => {
      const response = await axios.post(`/v1/clinical-reviews/`, data);
      return response.data.id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clinicalReviews"] });
      queryClient.invalidateQueries({
        queryKey: ["clinicalReviewStateHistory"],
      });
    },
  });
};

interface ICancelClinicalReviewData {
  reason: string;
}

export const useCancelClinicalReviewMutation = (clinicalReviewId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ICancelClinicalReviewData) => {
      await axios.put(`/v1/clinical-reviews/${clinicalReviewId}/cancel/`, data);
      return null;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clinicalReviews"] });
      queryClient.invalidateQueries({
        queryKey: ["clinicalReviewStateHistory", clinicalReviewId],
      });
    },
  });
};
