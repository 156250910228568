import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { Formula } from "src/models";

interface IProps {
  formula?: Formula;
}

const FormulaDetails = ({ formula }: IProps) => (
  <Card>
    <CardContent>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Controlled</TableCell>
            <TableCell>
              <Value text={formula?.controlled ? "Yes" : "No"} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Brand</TableCell>
            <TableCell>
              <Value
                text={`${formula?.brand} - ${formula?.isCuraleafBrand ? "Curaleaf Brand" : "Non-Curaleaf Brand"}`}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Form</TableCell>
            <TableCell>
              <Value text={formula?.form} />
            </TableCell>
          </TableRow>
          {formula?.form === "VAPE_CARTRIDGE" && (
            <TableRow>
              <TableCell>Quantity Per Cartridge</TableCell>
              <TableCell>
                <Value
                  text={
                    formula.quantityPerCartridge
                      ? formula.quantityPerCartridge.toString()
                      : ""
                  }
                />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Species</TableCell>
            <TableCell>
              <Value text={formula?.species} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Strain</TableCell>
            <TableCell>
              <Value text={formula?.strain} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Terpenes</TableCell>
            <TableCell>
              <Value text={formula?.terpenes.join(", ")} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>THC</TableCell>
            <TableCell>
              <Value percentage={formula?.thc} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>CBD</TableCell>
            <TableCell>
              <Value percentage={formula?.cbd} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>CBG</TableCell>
            <TableCell>
              <Value percentage={formula?.cbg} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>CBN</TableCell>
            <TableCell>
              <Value percentage={formula?.cbn} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Appearance</TableCell>
            <TableCell>
              <Value text={formula?.appearance} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Aroma</TableCell>
            <TableCell>
              <Value text={formula?.aroma} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cultivator</TableCell>
            <TableCell>
              <Value text={formula?.cultivator} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Parent Strain</TableCell>
            <TableCell>
              <Value text={formula?.parentStrain} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell>
              <Value text={formula?.sku} />
            </TableCell>
          </TableRow>
          {formula?.form === "FLOWER" && (
            <TableRow>
              <TableCell>Flower Grade</TableCell>
              <TableCell>
                <Value text={formula?.flowerGrade} />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Intended Use</TableCell>
            <TableCell>
              <Value text={formula?.intendedUse} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Manufacturer</TableCell>
            <TableCell>
              <Value text={formula?.manufacturer} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Irradiation Type</TableCell>
            <TableCell>
              <Value text={formula?.irradiationType} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ingredients</TableCell>
            <TableCell>
              <Value text={formula?.ingredients.join(", ")} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Suggested Use</TableCell>
            <TableCell>
              <Value text={formula?.suggestedUse.join(", ")} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

export default FormulaDetails;
