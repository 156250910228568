import * as z from "zod";

export enum FormulaState {
  ARCHIVED = "ARCHIVED",
  PENDING = "PENDING",
  PRESCRIBABLE = "PRESCRIBABLE",
  PRESCRIBABLE_IF_REWRITE = "PRESCRIBABLE_IF_REWRITE",
  UNPRESCRIBABLE = "UNPRESCRIBABLE",
}

const formulaStateHistorySchema = z.object({
  id: z.number().int().positive(),
  formulaId: z.string().uuid(),
  reason: z.string().nullable(),
  state: z.nativeEnum(FormulaState),
  staffId: z.number().int().positive().nullable(),
  staffEmail: z.string().email().nullable(),
  timestamp: z.coerce.date(),
});

export type FormulaStateHistory = z.infer<typeof formulaStateHistorySchema>;

export const newFormulaStateHistory = (data: unknown) =>
  formulaStateHistorySchema.parse(data);
