import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { AppointmentType } from "src/models";
import {
  Clinician,
  ClinicianSpecialism,
  ClinicianType,
  FollowUpPreference,
  LetterType,
  newClinician,
} from "src/models/clinician";
import { useQuery, useMutation } from "src/query";

export interface IClinicianData {
  appointmentTypes: AppointmentType[];
  clinicianType: ClinicianType;
  minimumHoursBeforeBooking: number;
  name: string;
  followUpPreference: FollowUpPreference;
  autoApprovalLetters: LetterType[];
  staffId: number;
  specialisms: ClinicianSpecialism[];
}

export const useCliniciansQuery = (showArchived?: boolean) =>
  useQuery<Clinician[]>({
    queryKey: ["clinicians", showArchived],
    queryFn: async () => {
      const params = { showArchived };
      const response = await axios.get("/v1/clinicians/", {
        params,
      });
      return response.data.clinicians.map((data: unknown) =>
        newClinician(data),
      );
    },
  });

export const useClinicianQuery = (clinicianId: string | undefined) => {
  return useQuery<Clinician>({
    queryKey: ["clinicians", clinicianId],
    queryFn: async () => {
      const response = await axios.get(`/v1/clinicians/${clinicianId}/`);
      return newClinician(response.data);
    },
    enabled: clinicianId !== undefined,
  });
};

export const useCliniciansBySpecialityGroupQuery = (
  specialityGroupId: number,
) => {
  return useQuery<Clinician[]>({
    queryKey: ["specialityGroupClinicians", specialityGroupId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/clinicians/speciality-group/${specialityGroupId}/`,
      );

      return response.data.clinicians.map((data: unknown) =>
        newClinician(data),
      );
    },
  });
};

export const useCreateClinician = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IClinicianData) =>
      await axios.post("/v1/clinicians/", data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clinicians"] });
      queryClient.invalidateQueries({ queryKey: ["appointmentLengths"] });
    },
  });
};

export const useEditClinicianMutation = (clinicianId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IClinicianData) =>
      await axios.put(`/v1/clinicians/${clinicianId}/`, data),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clinicians"] });
      queryClient.invalidateQueries({ queryKey: ["appointmentLengths"] });
      queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
    },
  });
};

export const useArchiveClinicianMutation = (clinicianId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.put(`/v1/clinicians/${clinicianId}/archived/`, {
        archived: true,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clinicians"] });
    },
  });
};

export const useUnarchiveClinicianMutation = (clinicianId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.put(`/v1/clinicians/${clinicianId}/archived/`, {
        archived: false,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clinicians"] });
    },
  });
};
