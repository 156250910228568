import {
  Search,
  Title,
  useDebounce,
  useHash,
} from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Tab from "@mui/material/Tab";
import { ChangeEvent, useState } from "react";
import { Link } from "wouter";

import CliniciansTable from "src/pages/Clinicians/CliniciansTable";

type TabState = "active" | "archived";

const Clinicians = () => {
  const [rawSearch, setRawSearch] = useState<string>("");
  const search = useDebounce(rawSearch);
  const [tab, setTab] = useHash<TabState>("active");

  return (
    <>
      <Title
        title="Clinicians"
        actions={
          <Button component={Link} to="/clinicians/new/" variant="contained">
            Add Clinician
          </Button>
        }
      />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as TabState)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Active" value="active" />
            <Tab label="Archived" value="archived" />
          </TabList>
        </TabContext>
        <Divider />
        <CardContent>
          <Grid container>
            <Search
              fullWidth
              label="Search"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setRawSearch(event.target.value)
              }
              value={rawSearch}
            />
          </Grid>
        </CardContent>
        <CliniciansTable search={search} tab={tab} />
      </Card>
    </>
  );
};
export default Clinicians;
