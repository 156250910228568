import {
  AutocompleteField,
  CheckboxField,
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { FormulaState, Form } from "src/models";
import { useCreateFormulaMutation } from "src/queries";

const MANUFACTURERS = [
  "Other",
  "Aurora",
  "MGC Pharma",
  "ECS",
  "Cellen",
  "Rokshaw",
  "IPS",
  "Tilray",
  "Northern Green Canada",
  "CannGroup",
  "Transvaal",
  "Althea",
  "Spectrum",
  "Cannatrek",
  "Bod pharma",
  "BOL",
  "Bedrocan",
  "PS Pharma services GmbH",
  "Khiron",
  "Canmed",
  "Bayer",
  "GW Pharma",
];

const BRANDS = ["CURALEAF", "FOUR_20", "FIND", "GRASSROOTS", "HUALA", "OTHER"];

const FormSchema = z.object({
  brand: z.string(),
  controlled: z.boolean(),
  internalName: z.string(),
  registeredName: z.string(),
  manufacturer: z.string(),
  form: z.union([z.nativeEnum(Form), z.literal("")]),
  startingState: z.nativeEnum(FormulaState),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const CreateNonCannbisFormula = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);

  const { mutateAsync: createNonCannabisFormula } = useCreateFormulaMutation();

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      brand: "",
      controlled: false,
      internalName: "",
      registeredName: "",
      manufacturer: "",
      form: "",
      startingState: FormulaState.PENDING,
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await createNonCannabisFormula({
        brand: data.brand,
        controlled: data.controlled,
        internalName: data.internalName,
        registeredName: data.registeredName,
        manufacturer: data.manufacturer,
        form: data.form as Form,
        startingState: data.startingState,
      });
      addToast("Formula Updated", "success");
      setLocation(`/formulas/`);
    } catch (error) {
      addToast(`Error: ${error}`, "error");
    }
  };

  return (
    <>
      <Title
        title="Create Non-Cannabis Formula"
        breadcrumbs={[
          { label: "Formulas", to: "/formulas/" },
          { label: `Create Non-Cannabis Formula` },
        ]}
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <TextField
                fullWidth
                label="Internal Name"
                name="internalName"
                required
              />
              <TextField
                fullWidth
                label="Registered Name"
                name="registeredName"
                required
              />
              <CheckboxField fullWidth label="Controlled" name="controlled" />
              <SelectField
                fullWidth
                name="startingState"
                label="Starting state"
                options={Object.values(FormulaState).map((state) => ({
                  label: state,
                  value: state,
                }))}
                required
              />
              <SelectField
                fullWidth
                name="manufacturer"
                label="Manufacturer"
                options={MANUFACTURERS.sort((a, b) => a.localeCompare(b)).map(
                  (manufacturer) => ({
                    label: manufacturer,
                    value: manufacturer,
                  }),
                )}
                required
              />
              <SelectField
                fullWidth
                name="form"
                label="Form"
                options={Object.values(Form).map((form) => ({
                  label: form,
                  value: form,
                }))}
                required
              />
              <AutocompleteField
                fullWidth
                name="brand"
                label="Brand"
                options={BRANDS.sort((a, b) => a.localeCompare(b)).map(
                  (brand) => ({
                    label: brand,
                    value: brand,
                  }),
                )}
                freeSolo={true}
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Create" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/formulas/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CreateNonCannbisFormula;
