import { z } from "zod";

const massEmailSchema = z.object({
  id: z.string().uuid(),
  staffId: z.number(),
  created: z.coerce.date(),
  sent: z.coerce.date().nullable(),
  subjectLine: z.string().min(1),
  body: z.string().min(1),
});

export type MassEmail = z.infer<typeof massEmailSchema>;

export const newMassEmail = (data: unknown): MassEmail =>
  massEmailSchema.parse(data);
