import { Title, useHash } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";

import { ClinicalReview } from "src/models";
import ClinicalReviewsTable from "src/pages/ClinicalReviews/ClinicalReviewsTable";
import { usePaginationSettings } from "src/pagination";

type TabState = "PENDING" | "ACCEPTED" | "DEFERRED";

const ClinicalReviews = () => {
  const [tab, setTab] = useHash<TabState>("PENDING");

  const [paginationSettings, { setPageNumber, setPageSize, toggleSort }] =
    usePaginationSettings<ClinicalReview>({
      pageNumber: 0,
      pageSize: 100,
      sortColumn: "stateTimestamp",
      sortDirection: "desc",
    });
  return (
    <>
      <Title title="Clinical Reviews" />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as TabState)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Pending" value="PENDING" />
            <Tab label="Accepted" value="ACCEPTED" />
            <Tab label="Deferred" value="DEFERRED" />
          </TabList>
          <Divider />
          <ClinicalReviewsTable
            state={tab}
            paginationSettings={paginationSettings}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            toggleSort={toggleSort}
          />
        </TabContext>
      </Card>
    </>
  );
};

export default ClinicalReviews;
