import {
  ToastContext,
  Title,
  SubmitButton,
  FormLinks,
  TextField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import axios from "axios";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import {
  useClinicalReviewQuery,
  useCancelClinicalReviewMutation,
} from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  clinicalReviewId: string;
}

const FormSchema = z.object({
  reason: z.string(),
});
type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

const CancelRequestForClinicalReview = ({ clinicalReviewId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: cancelClinicalReviewRequest } =
    useCancelClinicalReviewMutation(clinicalReviewId);
  const { data: review } = useClinicalReviewQuery(clinicalReviewId);

  const methods = useForm<FormType>({
    defaultValues: {
      reason: "",
    },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await cancelClinicalReviewRequest({
        reason: data.reason,
      });
      setLocation(`/clinical-reviews/${clinicalReviewId}/`);
      addToast("Clinical Review Request Cancelled", "success");
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "NOT_PENDING"
      ) {
        addToast("Request cannot be cancelled", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };
  return (
    <>
      <Title
        title={`Cancel ${convertEnumValueToReadableString(review?.type ?? "", " ")} review request for ${review?.patientName}`}
        breadcrumbs={[
          { to: "/clinical-reviews/", label: "Clinical Reviews" },
          {
            to: `/clinical-reviews/${clinicalReviewId}/`,
            label: `${review?.patientName} - ${convertEnumValueToReadableString(review?.type ?? "", " ")}`,
          },
          { label: "Request Clinical Review" },
        ]}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <Grid size={12}>
                <TextField fullWidth required label="Reason" name="reason" />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Cancel" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/clinical-reviews/${clinicalReviewId}/`,
                  },
                ]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default CancelRequestForClinicalReview;
