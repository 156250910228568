import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import { useContext } from "react";
import { Link } from "wouter";

import { AuthContext } from "src/AuthContext";
import { Role } from "src/models";
import MassEmailsTable from "src/pages/Tools/MassEmails/MassEmailsTable";

const MassEmails = () => {
  const { member } = useContext(AuthContext);

  return (
    <>
      <CardHeader
        sx={{ pt: 2 }}
        action={
          <Button
            component={Link}
            to={"/mass-emails/new/"}
            variant="contained"
            disabled={!member?.roles.includes(Role.CUSTOMER_SERVICE_LEAD)}
          >
            New Mass Email
          </Button>
        }
      />
      <MassEmailsTable />
    </>
  );
};

export default MassEmails;
