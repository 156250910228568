import {
  formatCurrency,
  formatDateTime,
} from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { differenceInDays } from "date-fns";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import { SubscriptionPayment } from "src/models";

interface IProps {
  payment: SubscriptionPayment;
}

const SubscriptionPaymentRow = ({ payment }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOverFourMonthsOld =
    120 < differenceInDays(new Date(), payment.timestamp);

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/subscription-payments/${payment.id}/refund/`}
          disabled={
            isOverFourMonthsOld ||
            payment.action === "REFUND" ||
            payment.state !== "ACCEPTED"
          }
        >
          Refund Payment
        </MenuItem>
      </Menu>
      <TableRow>
        <TableCell>{payment.id}</TableCell>
        <TableCell>{formatDateTime(payment.timestamp)}</TableCell>
        <TableCell>{payment.action}</TableCell>
        <TableCell>{payment.state}</TableCell>
        <TableCell>{formatCurrency(payment.amount)}</TableCell>
        <TableCell>
          {payment.staffId ? (
            <Link component={WLink} href={`/staff/${payment.staffId}/`}>
              {payment.staffEmail}
            </Link>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>{payment.reasonForRefund ?? payment.reason}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SubscriptionPaymentRow;
