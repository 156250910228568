import { Title, ToastContext } from "@curaleaf-international/components";
import { useContext } from "react";
import { useLocation } from "wouter";

import MassEmailForm from "src/components/MassEmailForm";
import {
  IMassEmailData,
  useCreateMassEmailMutation,
} from "src/queries/massEmails";

const CreateMassEmail = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createMassEmail } = useCreateMassEmailMutation();

  const onSubmit = async (data: IMassEmailData) => {
    try {
      await createMassEmail(data);
      setLocation("/tools/#mass-emails");
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <>
      <Title title="Create a Mass Email" />
      <MassEmailForm
        back="/tools/#mass-emails"
        label="Create"
        onSubmit={onSubmit}
        initialValues={{ subjectLine: "", body: "" }}
      />
    </>
  );
};

export default CreateMassEmail;
