import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const SubscriptionsHelp = () => (
  <CardContent>
    <Typography gutterBottom variant="h4" paragraph>
      Paid Subscription Types
    </Typography>

    <Typography gutterBottom variant="body1" paragraph>
      Patients are able to sign up to paid subscriptions via their patient app.
      They will be offered one of the two options below depending on how long
      they've been a Curaleaf Clinic patient. Paid subscriptions have a notice
      period of 3 months. A patient can cancel their subscription in their app
      at any time, but they will still be charged during the notice period. If
      they are placed on a free subscription they can cancel this themselves
      too. Declining treatment or being rejected at MDT triggers an immediate
      cancellation with the notice period being observed.
    </Typography>

    <Typography gutterBottom variant="h5" paragraph>
      CURA CL (Year 1)
    </Typography>

    <Typography gutterBottom variant="body1" paragraph>
      All patients are eligible for this subscription. It costs £15 per month
      and it includes 4 follow-up appointments and an additional initial
      appointment if they haven't had one yet. If they are currently signed up
      to this subscription and a year from their initial appointment passes,
      they will automatically be switched to CURA CLI, thus lowering their rate
      to £10 per month. They will be notified of this change via email.
    </Typography>

    <Typography gutterBottom variant="h5" paragraph>
      CURA CLI (Year 2 and beyond)
    </Typography>

    <Typography gutterBottom variant="body1" paragraph>
      This subscription costs £10 per month and it includes 4 appointments per
      year. This subscription is available to patients who have had their first
      initial appointment with us more than a year ago.
    </Typography>

    <Typography gutterBottom variant="h4" paragraph>
      Free Subscription Types
    </Typography>

    <Typography gutterBottom variant="body1" paragraph>
      Patients can only be subscribed to these by a Curaleaf Clinic staff
      member. Once a free subscription plan is added to their account, they will
      be notified via email and they will be able to manage their subscription
      via their app.
    </Typography>

    <Typography gutterBottom variant="h5" paragraph>
      Veteran
    </Typography>

    <Typography gutterBottom variant="body1" paragraph>
      This subscription is for patients who have provided us with proof of their
      veteran status. It includes up to 5 appointments per year.
    </Typography>

    <Typography gutterBottom variant="h5" paragraph>
      Foundation
    </Typography>

    <Typography gutterBottom variant="body1" paragraph>
      Subscription plan for Sapphire Foundation patients. This allows the
      patient to have an uncapped number of appointments free of charge. The
      system is set up to grant credits for up to 5 appointments if the patient
      hasn't had their initial and up to 4 credits per year afterwards. Should
      the patient need more appointments they will have to be awarded additional
      credits through patient services.
    </Typography>

    <Typography gutterBottom variant="h5" paragraph>
      Staff & Kin
    </Typography>

    <Typography gutterBottom variant="body1" paragraph>
      Staff members and their immediate family are eligible for this plan. Just
      like the Foundation plan, this covers all appointments, but if a patient
      needs more than 4 per year they will need to be granted additional credits
      by patient services.
    </Typography>
  </CardContent>
);

export default SubscriptionsHelp;
