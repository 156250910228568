import { zodDecimal } from "@curaleaf-international/components";
import * as z from "zod";

import { AppointmentStatus, AppointmentType } from "src/models/appointment";

const appointmentHistorySchema = z.object({
  id: z.number().int().positive(),
  appointmentId: z.string().uuid(),
  appointmentType: z.nativeEnum(AppointmentType),
  clinicianId: z.string().uuid(),
  clinicianName: z.string(),
  endAt: z.coerce.date(),
  length: z.coerce.number().int().positive(),
  patientId: z.string().uuid().nullable(),
  price: zodDecimal(),
  reason: z.string().nullable(),
  staffEmail: z.string().email().nullable(),
  staffId: z.number().int().positive().nullable(),
  startAt: z.coerce.date(),
  status: z.nativeEnum(AppointmentStatus),
  timestamp: z.coerce.date(),
});

export type AppointmentHistory = z.infer<typeof appointmentHistorySchema>;

export const newAppointmentHistory = (data: unknown): AppointmentHistory =>
  appointmentHistorySchema.parse(data);
