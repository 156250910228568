import { Title, useHash } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";

import Activities from "src/pages/Tools/Activities";
import MassEmails from "src/pages/Tools/MassEmails";

type TabState = "activities" | "mass-emails";

const Tools = () => {
  const [tab, setTab] = useHash<TabState>("activities");

  return (
    <>
      <Title title="Tools" />
      <TabContext value={tab}>
        <TabList
          onChange={(_, value: string) => setTab(value as TabState)}
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Activities" value="activities" />
          <Tab label="Mass Emails" value="mass-emails" />
        </TabList>
        <Card>
          <TabPanel value="activities">
            <Activities />
          </TabPanel>
          <TabPanel value="mass-emails">
            <MassEmails />
          </TabPanel>
        </Card>
      </TabContext>
    </>
  );
};

export default Tools;
