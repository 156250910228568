import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import {
  SubscriptionPaymentsData,
  newSubscriptionPaymentsData,
  SubscriptionPayment,
  newSubscriptionPayment,
} from "src/models";

export const useSubscriptionPaymentsQuery = (subscriptionId: string) => {
  return useQuery<SubscriptionPaymentsData>({
    queryKey: ["subscriptionPayments", subscriptionId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/subscription-payments/subscription/${subscriptionId}/`,
      );
      return newSubscriptionPaymentsData(response.data);
    },
  });
};

export const useSubscriptionPaymentQuery = (paymentId: string) => {
  return useQuery<SubscriptionPayment>({
    queryKey: ["subscriptionPayments", paymentId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/subscription-payments/${paymentId}/`,
      );
      return newSubscriptionPayment(response.data);
    },
  });
};

interface IPaymentRefundData {
  reason: string;
}

export const useRefundSubscriptionPaymentMutation = (paymentId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IPaymentRefundData) => {
      await axios.put(`/v1/subscription-payments/${paymentId}/refund/`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subscriptions"],
      });
      queryClient.invalidateQueries({
        queryKey: ["subscriptionPayments"],
      });
      queryClient.invalidateQueries({
        queryKey: ["subscriptionHistory"],
      });
    },
  });
};
