import { z } from "zod";

export enum ClinicalReviewType {
  VAPE_CARTRIDGE_PRESCRIBING = "VAPE_CARTRIDGE_PRESCRIBING",
  FIVE_ONE_EIGHT_B_FLOS_PRESCRIBING = "FIVE_ONE_EIGHT_B_FLOS_PRESCRIBING",
  FLOS_PRESCRIBING = "FLOS_PRESCRIBING",
}

export enum ClinicalReviewState {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DEFERRED = "DEFERRED",
  CANCELLED = "CANCELLED",
}

const clinicalReviewSchema = z.object({
  id: z.string().uuid(),
  assignedConsultantId: z.string().uuid().nullable(),
  assignedConsultantName: z.string().nullable(),
  patientId: z.string().uuid(),
  patientName: z.string(),
  type: z.nativeEnum(ClinicalReviewType),
  state: z.nativeEnum(ClinicalReviewState),
  stateTimestamp: z.coerce.date(),
  stateStaffId: z.number().int().positive(),
  stateStaffEmail: z.string().email(),
  stateReason: z.string().nullable(),
});

export type ClinicalReview = z.infer<typeof clinicalReviewSchema>;

export const newClinicalReview = (data: unknown): ClinicalReview =>
  clinicalReviewSchema.parse(data);
