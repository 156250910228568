import { formatDateTime, Value } from "@curaleaf-international/components";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { FormulaStateHistory } from "src/models";

interface IProps {
  history: FormulaStateHistory;
}

const FormulaStateHistoryRow = ({ history }: IProps) => {
  return (
    <>
      <TableRow>
        <TableCell>{history.state}</TableCell>
        <TableCell>{formatDateTime(history.timestamp)}</TableCell>

        <TableCell>
          {history.staffId && history.staffEmail ? (
            <Value
              link={{
                label: history.staffEmail,
                to: `/staff/${history.staffId}/`,
              }}
            />
          ) : null}
        </TableCell>
        <TableCell>{history.reason}</TableCell>
      </TableRow>
    </>
  );
};

export default FormulaStateHistoryRow;
