import {
  SubmitButton,
  TextField,
  FormLinks,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import MassEmailPreview from "src/components/MassEmailPreview";
import { useMassEmailsQuery } from "src/queries/massEmails";

const FormSchema = z.object({
  subjectLine: z.string().min(2),
  body: z.string().min(2),
});

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;
interface IProps {
  back: string;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<any>;
  initialValues: FormType;
  unsentMassEmailId?: string;
}

const MassEmailForm = ({
  back,
  label,
  onSubmit,
  initialValues,
  unsentMassEmailId,
}: IProps) => {
  const [previewData, setPreviewData] = useState(initialValues);
  const [previewOpen, setPreviewOpen] = useState(false);
  const { data: massEmails } = useMassEmailsQuery();

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    const subscription = methods.watch((value) => {
      setPreviewData({
        subjectLine: value.subjectLine || "",
        body: value.body || "",
      });
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, massEmails]);

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    await methods.handleSubmit(onSubmit)();
  };

  const setOpen = () => {
    setPreviewOpen(!previewOpen);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit}>
        <Card>
          <CardContent>
            {massEmails &&
            massEmails[0]?.sent === null &&
            !unsentMassEmailId ? (
              <Alert severity="error">
                There is already a mass email awaiting sending in the queue. If
                you create another one and leave more than one email pending, it
                will result in patients receiving multiple emails from us at the
                same time. <br />
                <br />
                As a reminder, emails that have been queued for more than 1 hour
                are sent automatically on the hour, between 11am and 5pm Monday
                to Friday.
              </Alert>
            ) : (
              !unsentMassEmailId && (
                <Alert severity="warning">
                  Do not use this for marketing or advertising emails. It will
                  degrade our email rating and make all our emails more likely
                  to be classified as spam. <br />
                  <br />
                  Once created successfully, the email will be placed in the
                  queue and will send automatically the next time 11am or 5pm
                  comes around during Monday to Friday.
                </Alert>
              )
            )}
            <TextField
              fullWidth
              label="Subject Line"
              name="subjectLine"
              required
            />
            <TextField
              fullWidth
              label="Email Body"
              name="body"
              multiline={true}
              rows={8}
              required
            />
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label={label} disableClean={true} />
            <Button onClick={setOpen}>Preview</Button>
            <Dialog onClose={setOpen} open={previewOpen}>
              <MassEmailPreview
                subjectLine={previewData.subjectLine}
                body={previewData.body}
              />
            </Dialog>
            <FormLinks links={[{ label: "Back", to: back }]} />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default MassEmailForm;
