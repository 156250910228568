import { Value } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import RequiresRoles from "src/components/RequiresRoles";
import { ClinicalReview, ClinicalReviewState, Role } from "src/models";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  clinicalReview: ClinicalReview;
}

const ClinicalReviewTableRow = ({ clinicalReview }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <RequiresRoles
          roles={[
            Role.CONSULTANT,
            Role.GENERAL_PRACTITIONER,
            Role.PHARMACIST,
            Role.PHARMACIST_TECHNICIAN,
          ]}
        >
          <MenuItem
            component={WLink}
            onClick={handleClose}
            disabled={clinicalReview.state !== ClinicalReviewState.PENDING}
            to={`/clinical-reviews/${clinicalReview.id}/cancel/`}
          >
            Cancel
          </MenuItem>
        </RequiresRoles>
      </Menu>
      <TableRow>
        <TableCell align="center">
          <Value
            link={{
              label: convertEnumValueToReadableString(clinicalReview.type, " "),
              to: `/clinical-reviews/${clinicalReview.id}/`,
            }}
          />
        </TableCell>
        <TableCell align="center">
          <Value
            link={{
              label: clinicalReview.patientName,
              to: `/patients/${clinicalReview.patientId}/`,
            }}
          />
        </TableCell>
        <TableCell align="center">
          {clinicalReview.assignedConsultantId &&
          clinicalReview.assignedConsultantName ? (
            <Value
              link={{
                label: clinicalReview.assignedConsultantName,
                to: `/clinicians/${clinicalReview.assignedConsultantId}/`,
              }}
            />
          ) : (
            <Value text="Not Assigned" />
          )}
        </TableCell>
        <TableCell align="center">
          <Value text={clinicalReview.state} />
        </TableCell>
        <TableCell align="center">
          <Value dateTime={clinicalReview.stateTimestamp} />
        </TableCell>
        <TableCell align="center">
          <Value text={clinicalReview.stateReason} />
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ClinicalReviewTableRow;
