import { SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { MassEmail } from "src/models";
import MassEmailRow from "src/pages/Tools/MassEmails/MassEmailsRow";
import { useMassEmailsQuery } from "src/queries";

const MassEmailsTable = () => {
  const { data: massEmails } = useMassEmailsQuery();

  let rows;
  if (massEmails === undefined) {
    rows = <SkeletonRow cols={4} />;
  }
  if (massEmails?.length === 0) {
    rows = (
      <TableRow>
        <TableCell colSpan={4}>No mass mails found.</TableCell>
      </TableRow>
    );
  } else {
    rows = massEmails?.map((massEmail: MassEmail) => (
      <MassEmailRow key={massEmail.id} massEmail={massEmail} />
    ));
  }

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Created</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default MassEmailsTable;
