import {
  Value,
  formatDate,
  sortByKey,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { isAfter } from "date-fns";

import { PaymentAction, Subscription } from "src/models";
import {
  useGetSubscriptionHistoryQuery,
  usePatientQuery,
  useSubscriptionPaymentsQuery,
} from "src/queries";

interface IProps {
  subscription: Subscription;
}

const SubscriptionDetails = ({ subscription }: IProps) => {
  const { data: patient } = usePatientQuery(subscription?.patientId);
  const { data: paymentsData } = useSubscriptionPaymentsQuery(subscription.id);
  const { data: history } = useGetSubscriptionHistoryQuery(subscription.id);

  let remainingNoticePeriodPayments = 0;
  const isPaidSubscription =
    subscription.type === "CURA_CL" || subscription.type === "CURA_CLI";

  if (
    isPaidSubscription &&
    subscription.status === "CANCELLED" &&
    paymentsData?.nextPaymentDate &&
    history
  ) {
    const cancellationHistory = history
      .sort(sortByKey((entry) => [entry.timestamp], "desc"))
      .find((entry) => entry.status === "CANCELLED");
    const chargedNoticePeriodPayments = cancellationHistory
      ? paymentsData?.payments.filter(
          (payment) =>
            isAfter(payment.timestamp, cancellationHistory.timestamp) &&
            payment.action === PaymentAction.SALE,
        )
      : null;
    remainingNoticePeriodPayments =
      3 -
      (chargedNoticePeriodPayments ? chargedNoticePeriodPayments.length : 3);
  }

  return (
    <Card>
      <CardHeader title="Details" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>{subscription.status}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell>{formatDate(subscription.startDate)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>{subscription.type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Stripe</TableCell>
              <TableCell>
                <Link
                  href={`https://dashboard.stripe.com/subscriptions/${subscription.stripeId}`}
                  target="_blank"
                >
                  {subscription.stripeId}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Patient Name</TableCell>
              <TableCell>
                <Value
                  link={
                    patient !== undefined
                      ? {
                          label: `${patient.title} ${patient.name}`,
                          to: `/patients/${patient.id}/`,
                        }
                      : undefined
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Next Payment Date</TableCell>
              <TableCell>
                {paymentsData?.nextPaymentDate
                  ? formatDate(paymentsData?.nextPaymentDate)
                  : "N/A"}
                {remainingNoticePeriodPayments !== 0
                  ? ` - ${remainingNoticePeriodPayments} payments left`
                  : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SubscriptionDetails;
