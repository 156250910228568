import {
  SubmitButton,
  FormLinks,
  ToastContext,
  Title,
  Value,
  TextField,
  formatCurrency,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link as WLink, useLocation } from "wouter";
import * as z from "zod";

import {
  usePatientQuery,
  useRefundSubscriptionPaymentMutation,
  useSubscriptionPaymentQuery,
} from "src/queries";

const FormSchema = z.object({
  reason: z.string().min(2),
});

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  paymentId: string;
}

const RefundSubscriptionPayment = ({ paymentId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: payment } = useSubscriptionPaymentQuery(paymentId);
  const { mutateAsync: refundPayment } =
    useRefundSubscriptionPaymentMutation(paymentId);
  const { data: patient } = usePatientQuery(payment?.patientId);

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      reason: "",
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await refundPayment(data);
      addToast("Payment refunded", "success");
      setLocation(`/subscriptions/${payment?.subscriptionId}/#payments`);
    } catch (error) {
      addToast(`Error: ${error}`, "error");
    }
  };

  return (
    <>
      <Title
        title="Refund subscription payment"
        breadcrumbs={[
          {
            label: "Subscription",
            to: `/subscriptions/${payment?.subscriptionId}/`,
          },
          {
            label: `Subscription payments - ${patient?.name}`,
            to: `/subscriptions/${payment?.subscriptionId}/#payments`,
          },
          { label: "Refund subscription payment" },
        ]}
      />
      <Card sx={{ mb: 2 }}>
        <CardHeader title="Details" />
        <Divider />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>
                  <Value text={paymentId} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>
                  <Value date={payment?.timestamp} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>
                  <Value text={payment?.action} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>State</TableCell>
                <TableCell>
                  <Value text={payment?.state} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>
                  <Value
                    text={payment?.amount && formatCurrency(payment.amount)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Staff Id</TableCell>
                <TableCell>
                  {payment?.staffId ? (
                    <Link component={WLink} href={`/staff/${payment.staffId}/`}>
                      {payment.staffEmail}
                    </Link>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <TextField fullWidth label="Reason" name="reason" required />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton disabled={!patient || !payment} label="Confirm" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/subscriptions/${payment?.subscriptionId}/#payments`,
                  },
                ]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default RefundSubscriptionPayment;
