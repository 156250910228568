import {
  sortByKey,
  useDebounce,
  Search,
  Value,
  formatDateTime,
  SkeletonRow,
} from "@curaleaf-international/components";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Fuse from "fuse.js";
import { ChangeEvent, useState } from "react";

import {
  checkIfFormulaIsCannabisBased,
  Formula,
  FormulaState,
} from "src/models";
import { useFormulasQuery } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  state: string;
}
type Direction = "asc" | "desc";
type OrderableProperties = "internalName" | "form" | "state";

const FormulasTable = ({ state }: IProps) => {
  const [order, setOrder] = useState<Direction>("asc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("internalName");
  const { data: formulary } = useFormulasQuery();
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);

  const sortKey = (formulas: Formula) => [formulas[orderBy]];

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filterFormulas = (formulas: Formula[]) => {
    if (search !== "") {
      const fuse = new Fuse(formulas, {
        keys: ["internalName"],
      });
      return fuse.search(search).map((value) => value.item);
    }
    return formulas.sort(sortByKey(sortKey, order));
  };
  return (
    <>
      <CardContent>
        <Search
          fullWidth
          label="Search"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setRawSearch(event.target.value)
          }
          value={rawSearch}
          sx={{ marginBottom: 2 }}
        />
      </CardContent>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "internalName"}
                  direction={order}
                  onClick={onSortClick("internalName")}
                >
                  Internal Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "form"}
                  direction={order}
                  onClick={onSortClick("form")}
                >
                  Form
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "state"}
                  direction={order}
                  onClick={onSortClick("state")}
                >
                  State
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formulary ? (
              filterFormulas(formulary)
                .filter((formula) => {
                  switch (state) {
                    case "prescribable":
                      return (
                        formula.state === FormulaState.PRESCRIBABLE &&
                        checkIfFormulaIsCannabisBased(formula)
                      );
                    case "pending":
                      return formula.state === FormulaState.PENDING;
                    case "prescribableIfRewrite":
                      return (
                        formula.state ===
                          FormulaState.PRESCRIBABLE_IF_REWRITE &&
                        checkIfFormulaIsCannabisBased(formula)
                      );
                    case "unprescribable":
                      return (
                        formula.state === FormulaState.UNPRESCRIBABLE &&
                        checkIfFormulaIsCannabisBased(formula)
                      );
                    case "archived":
                      return formula.stateReason === FormulaState.ARCHIVED;
                    case "nonCannabis":
                      return !checkIfFormulaIsCannabisBased(formula);
                  }
                })
                .map((formula) => (
                  <TableRow key={formula.id}>
                    <TableCell>
                      <Value
                        link={{
                          label: formula.internalName,
                          to: `/formulas/${formula.id}/`,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Value
                        text={`${convertEnumValueToReadableString(formula.form, " ")}`}
                      />
                    </TableCell>
                    <TableCell>
                      <Value
                        text={`${formula.state} on ${formatDateTime(formula.stateTimestamp)}`}
                      />
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <SkeletonRow cols={3} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FormulasTable;
