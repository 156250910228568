import {
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { FormulaState } from "src/models";
import { useFormulaQuery, useUpdateFormulaStateMutation } from "src/queries";

interface IProps {
  formulaId: string;
}

const FormSchema = z.object({
  state: z.nativeEnum(FormulaState),
  reason: z.string(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const UpdateFormulaStateForm = ({ formulaId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: updateFormulaState } =
    useUpdateFormulaStateMutation(formulaId);

  const initialValues = {
    state: formula?.state ?? FormulaState.PENDING,
    reason: "",
  };

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    methods.reset(initialValues);
  }, [formula]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await updateFormulaState(data);
      addToast("Formula Updated", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch (error) {
      addToast(`Error: ${error}`, "error");
    }
  };

  return (
    <>
      <Title
        title="Update Formula State"
        breadcrumbs={[
          { label: "Formulas", to: "/formulas/" },
          {
            label: `${formula?.internalName}`,
            to: `/formulas/${formula?.id}/`,
          },
          { label: `Update Formula State` },
        ]}
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <TextField fullWidth label="Reason" name="reason" required />
              <SelectField
                fullWidth
                name="state"
                label="New state"
                options={Object.values(FormulaState).map((state) => ({
                  label: state,
                  value: state,
                }))}
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton disabled={formula === undefined} label="Update" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/formulas/${formulaId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default UpdateFormulaStateForm;
