import * as z from "zod";

import { AppointmentType } from "src/models/appointment";

export enum ClinicianSpecialism {
  GENERAL = "GENERAL",
  NEUROLOGY = "NEUROLOGY",
  PAIN = "PAIN",
  PALLIATIVE = "PALLIATIVE",
  PSYCHIATRY = "PSYCHIATRY",
  REHAB = "REHAB",
}

export enum ClinicianType {
  CONSULTANT = "CONSULTANT",
  PHARMACIST = "PHARMACIST",
  GENERAL_PRACTITIONER = "GENERAL_PRACTITIONER",
}

export enum FollowUpPreference {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  SEES_PATIENTS_FOR_FIRST_FOLLOW_UP = "SEES_PATIENTS_FOR_FIRST_FOLLOW_UP",
  DOES_NOT_SEE_PATIENTS_FOR_FIRST_FOLLOW_UP = "DOES_NOT_SEE_PATIENTS_FOR_FIRST_FOLLOW_UP",
}

export enum LetterType {
  TRAVEL_LETTERS = "TRAVEL_LETTERS",
}

const clinicianSchema = z.object({
  id: z.string().uuid(),
  appointmentTypes: z.array(z.nativeEnum(AppointmentType)),
  archived: z.coerce.date().nullable(),
  archivedBy: z.number().int().positive().nullable(),
  archivedByEmail: z.string().email().nullable(),
  clinicianType: z.nativeEnum(ClinicianType),
  minimumHoursBeforeBooking: z.number().int().positive(),
  name: z.string(),
  followUpPreference: z.nativeEnum(FollowUpPreference),
  autoApprovalLetters: z.array(z.nativeEnum(LetterType)),
  specialisms: z.array(z.nativeEnum(ClinicianSpecialism)),
  staffId: z.coerce.number().int().positive(),
  email: z.string().email(),
});

export type Clinician = z.infer<typeof clinicianSchema>;

export const newClinician = (data: unknown): Clinician =>
  clinicianSchema.parse(data);
