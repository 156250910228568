import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import FormulaStateHistoryRow from "src/pages/Formula/FormulaStateHistoryRow";
import { useFormulaStateHistoryQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const FormulaStateHistory = ({ formulaId }: IProps) => {
  const { data: histories } = useFormulaStateHistoryQuery(formulaId);
  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Actioned By</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {histories
              ?.sort(sortByKey((formula) => [formula.timestamp]))
              .map((history) => (
                <FormulaStateHistoryRow key={history.id} history={history} />
              )) ?? <SkeletonRow cols={4} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default FormulaStateHistory;
