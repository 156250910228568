import {
  formatDateTime,
  SkeletonRow,
  Value,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { ClinicalReview } from "src/models";
import { usePatientClinicalReviewsQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const PatientClinicalReviews = ({ patientId }: IProps) => {
  const { data: reviews } = usePatientClinicalReviewsQuery(patientId);

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Last Updated By</TableCell>
              <TableCell>State Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reviews === undefined ? (
              <SkeletonRow cols={4} />
            ) : reviews.length === 0 ? (
              <TableRow>
                <TableCell>No Clinical Reviews Found.</TableCell>
              </TableRow>
            ) : (
              reviews.map((review: ClinicalReview) => (
                <TableRow key={review.id}>
                  <TableCell>
                    <Value
                      link={{
                        to: `/clinical-reviews/${review.id}/`,
                        label: review.type,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Value
                      text={`${review.state} ${formatDateTime(review.stateTimestamp)}`}
                    />
                  </TableCell>
                  <TableCell>
                    <Value
                      link={{
                        label: review.stateStaffEmail,
                        to: `/staff/${review.stateStaffId}/`,
                      }}
                    />
                  </TableCell>
                  <TableCell>{review.stateReason}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default PatientClinicalReviews;
